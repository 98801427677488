<template>
    <div class="brands">
        <b-overlay :show="showLoader" variant="transperant" opacity="1" spinner-variant="primary">

            <!-- Stats Card Horizontal -->
            <b-row id="links">
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="LinkIcon" :statistic="stats.links" statistic-title="LINKS" />
                </b-col>
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="ApertureIcon" color="success" :statistic="stats.source"
                        statistic-title="SOURCE" />
                </b-col>
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="MousePointerIcon" color="danger" :statistic="stats.clicks"
                        statistic-title="CLICKS" />
                </b-col>
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="EyeIcon" color="warning" :statistic="stats.unique_visits"
                        statistic-title="UNIQUE VISITS" />
                </b-col>
            </b-row>

            <b-card no-body>
                <b-card-header>
                    <b-card-title>Links</b-card-title>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="btn-icon ml-auto"
                        @click="addModalShow = true">
                        Add New Link
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </b-card-header>
                <div class="mb-2 mx-1">
                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Show</label>
                            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="pageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                            <label>entries</label>
                        </b-col>
                        <!-- Search -->
                        <b-col cols="12" md="4">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <b-row>
                    <b-col cols="12">
                        <b-table hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
                            :items="links" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection" :filter="filter" show-empty
                            empty-text="لم يتم العثور على سجلات مطابقة" :filter-included-fields="filterOn"
                            @filtered="onFiltered" @row-clicked="onRowClicked">
                            <template #cell(user)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar size="32" />
                                    </template>
                                    <b-link class="font-weight-bold d-block text-nowrap">
                                        {{ data.item.user.full_name }}
                                    </b-link>
                                    <small class="text-muted">{{ data.item.user.email }}</small>
                                </b-media>
                            </template>

                            <template #cell(brand)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar size="32" :src="data.item.brand.image" />
                                    </template>
                                    <b-link class="font-weight-bold d-block text-nowrap">
                                        {{ data.item.brand.name }}
                                    </b-link>
                                    <small class="text-muted">@{{ data.item.brand.signature }}</small>
                                </b-media>
                            </template>

                            <template #cell(permission)="data">
                                <b-badge
                                    :variant="data.item.permission == 'owner' ? 'light-success' : data.item.permission == 'admin' ? 'light-primary' : 'light-secondary'">
                                    {{ data.item.permission }}
                                </b-badge>
                            </template>

                            <template #cell(slug)="data">
                                <b-badge :href="data.item.slug" target="_blank" variant="primary">
                                    <feather-icon icon="LinkIcon" class="mr-25" />
                                    <span>{{ data.item.slug }}</span>
                                </b-badge>
                            </template>

                            <template #cell(redirect_to)="data">
                                <b-badge :href="data.item.redirect_to" target="_blank" variant="primary">
                                    <feather-icon icon="LinkIcon" class="mr-25" />
                                    <span v-text="data.item.redirect_to.length < 30 ? data.item.description : data.item.redirect_to.substring(0, 30) + '...'"></span>
                                </b-badge>
                            </template>
                            <template #cell(action)="data">
                                <div class="float-right">
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger"
                                        class="btn-icon mr-1" @click="deleteLink(data.item.id)">
                                        <feather-icon icon="TrashIcon" />
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"
                                        class="btn-icon mr-1" @click="editModal(data.item)">
                                        <feather-icon icon="SettingsIcon" />
                                    </b-button>
                                </div>
                            </template>

                            <template #cell(created_at)="data">
                                <b-badge variant="primary">
                                    <feather-icon icon="StarIcon" class="mr-25" />
                                    <span>{{ moment(data.item.created_at).format('llll') }}</span>
                                </b-badge>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>

                <b-row class="p-2">
                    <b-col cols="12">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                            class="mb-0 mt-1 mt-sm-0" pills first-number last-number prev-class="next-item"
                            next-class="prev-item" />
                    </b-col>
                </b-row>
            </b-card>
            <template v-if="link_stat == null">
                <b-card title="Click Any Link" class="text-center">
                    <b-card-text>
                        Click any link to see statistics related to.
                    </b-card-text>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" href="#links">
                        Go To Links
                    </b-button>
                </b-card>
            </template>
            <template v-else>
                <b-overlay :show="showLoaderLink" variant="transperant" opacity="1" spinner-variant="primary">
                    <Visit :data="link_stat" :key="reloadVisitcomp" />
                </b-overlay>
            </template>
        </b-overlay>

        <!-- add modal -->
        <b-modal id="modal-2" ref="modal-add" v-model="addModalShow" title="Link Shortener" ok-title="Send"
            cancel-title="Cancel" cancel-variant="outline-secondary" no-close-on-backdrop @hidden="resetAddModal"
            @ok="handleOkAddModal">
            <validation-observer ref="simpleRules">
                <b-form @submit.prevent="handleOkAddModal">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Redirect To" label-for="redirect_to">
                                <validation-provider #default="{ errors }" name="Redirect To" rules="required">
                                    <b-form-input v-model="link.redirect_to" :state="errors.length > 0 ? false : null"
                                        placeholder="Redirect To" id="redirect_to" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Description" label-for="description">
                                <validation-provider #default="{ errors }" name="Description" rules="required">
                                    <b-form-textarea v-model="link.description" :state="errors.length > 0 ? false : null"
                                        placeholder="Description" id="description" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>

            <template #modal-footer>
                <b-button variant="outline-secondary" class="mr-1" @click="resetAddModal">
                    Cancel
                </b-button>
                <b-button variant="primary" :disabled="loading" @click="handleOkAddModal" class="mr-1">
                    <template v-if="!loading">
                        Save
                    </template>
                    <template v-else>
                        <b-spinner small />
                    </template>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>


<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email } from '@validations'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import {
    BCard, BCardText, BRow, BCol, BButton, BAvatar, BLink, BBadge, BTabs, BTab, BMedia, BImg, BFormInput, BFormGroup, BForm,
    BOverlay, BFormCheckbox, BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup,
    BInputGroupAppend, BCardTitle, BCardHeader, BSpinner, BFormTextarea
} from 'bootstrap-vue'
import Visit from './components/Visit.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
export default {
    components: {
        Visit, StatisticCardHorizontal, StatisticCardWithLineChart, ToastificationContent, ValidationProvider, ValidationObserver,
        BCard, BCardText, BRow, BCol, BButton, BLink, BAvatar, BBadge, BTabs, BTab, BMedia, BImg, BFormInput,
        BFormGroup, BForm, BOverlay, BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup, BCardTitle, BCardHeader,
        BInputGroupAppend, BFormCheckbox, vSelect, BSpinner, BFormTextarea
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            reloadVisitcomp: 0,
            showLoaderLink: false,
            addModalShow: false,
            loading: false,
            showLoader: true,
            perPage: 5,
            pageOptions: [5, 15, 20],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            fields: [
                { key: 'id', label: 'ID', sortable: true },
                { key: 'slug', label: 'slug' },
                { key: 'redirect_to', label: 'redirect_to' },
                { key: 'description', label: 'description' },
                { key: 'created_at', label: 'Created At' },
                { key: 'action', label: '' },
            ],
            links: [],
            link: {
                id: null,
                slug: '',
                redirect_to: '',
                description: '',
            },
            stats: {
                links: 0,
                source: 0,
                clicks: 0,
                unique_visits: 0
            },
            link_stat: null,
            required, email
        }
    },
    mounted() {
        this.getLinks()
        this.getStats()
    },
    methods: {
        onRowClicked(item, index, event) {
            this.showLoaderLink = true
            console.log(item.id);
            axios.get(`/links/${item.id}/`)
                .then(response => {
                    console.log(response);
                    this.link_stat = response.data
                    this.reloadVisitcomp += 1
                    this.showLoaderLink = false
                })
                .catch(error => {
                    console.log(error);
                })
        },
        editModal(link) {
            this.link.id = link.id
            this.link.slug = link.slug
            this.link.redirect_to = link.redirect_to
            this.link.description = link.description
            this.addModalShow = true
        },
        handleOkAddModal(bvModalEvt) {
            this.loading = true
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.submitForm()
        },
        async submitForm() {
            await this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    var action, endpoint;
                    if (this.link.id != null) {
                        endpoint = `/links/${this.link.id}/`
                        action = 'patch'
                    } else {
                        endpoint = '/links/'
                        action = 'post'
                    }
                    axios[action](endpoint, this.link)
                        .then((response) => {
                            if (response.data.code == 201 || response.data.code == 200){
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Link Saved',
                                        icon: 'CheckIcon',
                                        text: 'link was saved successfully.',
                                        variant: 'success',
                                    },
                                })
                                this.loading = false
                                this.addModalShow = false
                                this.resetAddModal()
                                this.getLinks()
                                console.log(response);
                            } else {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Upgrade Plan!',
                                        icon: 'AlertCircleIcon',
                                        text: 'Links quota exceeded, please upgrade plan.',
                                        variant: 'danger',
                                    },
                                })
                                this.loading = false
                            }
                        })
                        .catch((error) => {
                            this.loading = false
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Warning !',
                                    icon: 'AlertCircleIcon',
                                    text: 'Something Went Wrong.',
                                    variant: 'danger',
                                },
                            })
                            JSON.stringify(error);
                        })
                } else {
                    setTimeout(() => this.loading = false, 500);
                }
            })
        },
        resetAddModal() {
            this.link.id = null
            this.link.slug = ''
            this.link.redirect_to = ''
            this.link.description = ''
            this.addModalShow = false
        },
        deleteLink(id) {
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    axios.delete(`/links/${id}/`)
                        .then(response => {
                            console.log(response);
                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'The link has been deleted.',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            })
                            this.getLinks()
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
        },
        async getLinks() {
            await axios.get("/links")
                .then(response => {
                    console.log(response);
                    this.links = response.data
                    this.totalRows = response.data.length
                })
                .catch(error => {
                    console.log(error);
                })
        },
        async getStats() {
            await axios.get("/links/stats/")
                .then(response => {
                    console.log(response);
                    this.stats = response.data
                    this.showLoader = false
                })
                .catch(error => {
                    console.log(error);
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.popular {
    border: 1px dashed var(--primary);
}
</style>