<template>
    <div class="brands">
        <b-card>
            <b-row>
                <!-- User Info: Left col -->
                <b-col cols="12" class="d-flex justify-content-between flex-column">
                    <!-- User Avatar & Action Buttons -->
                    <div class="d-flex justify-content-start">
                        <b-avatar size="lg" variant="light-primary" id="tour-card">
                            <feather-icon size="30" icon="LinkIcon" />
                        </b-avatar>
                        <div class="d-flex flex-column ml-1">
                            <div class="">
                                <h3 class="mb-0">{{ data.link.slug }}</h3>
                                <span class="card-text">
                                    <feather-icon icon="LinkIcon" size="13" />
                                    {{ data.link.description }}
                                </span>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <!-- Stats Card w/ line chart -->
        <b-row>
            <b-col md="4" sm="6">
                <statistic-card-with-line-chart icon="ApertureIcon" color="warning" :statistic="data.source" statistic-title="SOURCE"
                    :chart-data="[{ name: 'Source', data: [150, 200, 125, 225, 200, 250] }]" />
            </b-col>
            <b-col md="4" sm="6">
                <statistic-card-with-line-chart icon="MousePointerIcon" :statistic="data.clicks" statistic-title="CLICKS"
                    :chart-data="[{ name: 'Clicks', data: [150, 200, 125, 225, 200, 250] }]" />
            </b-col>
            <b-col md="4" sm="6">
                <statistic-card-with-line-chart icon="EyeIcon" color="success" :statistic="data.unique_visits"
                    statistic-title="UNIQUE VISITS"
                    :chart-data="[{ name: 'Unique Visits', data: [150, 200, 125, 225, 200, 250] }]" />
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4" sm="6">
                <statistic-card-with-line-chart icon="MonitorIcon" color="info" :statistic="data.pc" statistic-title="PC CLICKS"
                    :chart-data="[{ name: 'PC', data: [150, 200, 125, 225, 200, 250] }]" />
            </b-col>
            <b-col md="4" sm="6">
                <statistic-card-with-line-chart icon="SmartphoneIcon" color="danger" :statistic="data.mobile" statistic-title="MOBILE CLICKS"
                    :chart-data="[{ name: 'MOBILE', data: [150, 200, 125, 225, 200, 250] }]" />
            </b-col>
            <b-col md="4" sm="6">
                <statistic-card-with-line-chart icon="CpuIcon" color="secondary" :statistic="data.device"
                    statistic-title="DEVICES"
                    :chart-data="[{ name: 'Visits', data: [150, 200, 125, 225, 200, 250] }]" />
            </b-col>
        </b-row>
        <b-card no-body>
            <b-card-header>
                <b-card-title>Visits</b-card-title>
            </b-card-header>
            <div class="mb-2 mx-1">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                        <label>entries</label>
                    </b-col>
                    <!-- Search -->
                    <b-col cols="12" md="4">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
                        </div>
                    </b-col>
                </b-row>
            </div>
            <b-row>
                <b-col cols="12">
                    <b-table hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
                        :items="links" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection" :filter="filter" show-empty
                        empty-text="لم يتم العثور على سجلات مطابقة" :filter-included-fields="filterOn"
                        @filtered="onFiltered">
                    </b-table>
                </b-col>
            </b-row>

            <b-row class="p-2">
                <b-col cols="12">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                        class="mb-0 mt-1 mt-sm-0" pills first-number last-number prev-class="next-item"
                        next-class="prev-item" />
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>


<script>
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import {
    BCard, BCardText, BRow, BCol, BButton, BAvatar, BLink, BBadge, BTabs, BTab, BMedia, BImg, BFormInput, BFormGroup, BForm,
    BOverlay, BFormCheckbox, BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup,
    BInputGroupAppend, BCardTitle, BCardHeader, BSpinner, BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
export default {
    components: {
        StatisticCardWithLineChart,
        BCard, BCardText, BRow, BCol, BButton, BLink, BAvatar, BBadge, BTabs, BTab, BMedia, BImg, BFormInput,
        BFormGroup, BForm, BOverlay, BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup, BCardTitle, BCardHeader,
        BInputGroupAppend, BFormCheckbox, vSelect, BSpinner, BFormTextarea
    },
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            perPage: 5,
            pageOptions: [5, 15, 20],
            totalRows: this.data.visits.length || 0,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            fields: [
                { key: 'id', label: 'ID', sortable: true },
                { key: 'source', label: 'source' },
                { key: 'device', label: 'device' },
                { key: 'browser', label: 'browser' },
                { key: 'created_at', label: 'Created At' },
                { key: 'action', label: '' },
            ],
            links: this.data.visits || []
        }
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.popular {
    border: 1px dashed var(--primary);
}
</style>